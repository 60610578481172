@import 'bootstrap/scss/bootstrap.scss';
:root {
  --bs-primary: #eabf00;
  --bs-primary-rgb: 234, 191, 0;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html, body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: #000;
  background: #FFF;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.demoPage {
  max-width: 1000px;
  margin: auto;
  padding: 70px 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.listTitle {
  padding: 20px 10px;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 991px) {
  .product-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 767px) {
  .product-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 575px) {
  .product-list {
    grid-template-columns: 1fr;
  }
}
.product-list .item {
  position: relative;
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 30px;
  cursor: default;
  outline: none;
}
.product-list .item img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.product-list .item .name {
  position: relative;
  padding:20px;
  height: 70px
}
.product-list .item .name::before {
  content: "";
  width: 20px;
  height: 2px;
  background-color: var(--bs-primary);
  position: absolute;
  bottom: 0px;
  left: 50%;
  margin-left: -10px;
}
.product-list .item .price {
  padding: 20px 0;
}
.product-list .item .price b {
  font-size: 1.5em;
  margin-left: 5px;
}
.product-list .item .atc {
  border: 0;
  display: block;
  background-color: #1b1a20;
  color: #FFF;
  padding: 15px 0px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.2s ease 0s;
  font-size: 16px;
}
.product-list .item:hover .atc {
  background-color: var(--bs-primary);
}


.shoppingCart {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 450px;
  background-color: #1b1a20;
  box-sizing: border-box;
  z-index: 99;
  transition: 0.2s ease;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
}
.shoppingCart.show {
  // transform: translateX(0);
  transform: inherit;
}


.shoppingCart .btnCart {
  border: 0px;
  padding: 0px;
  width: 50px;
  height: 50px;
  color: #ececec;
  background-color: #0c0b10;
  text-align: center;
  line-height: 50px;
  position: absolute;
  top: 0px;
  left: -50px;
  cursor: pointer;
  z-index: 2;
}
@media (max-width:500px) {
  .shoppingCart.show .btnCart {
    left: 0;
  }
}

.shoppingCart .header {
  color: #ececec;
  box-sizing: border-box;
  text-align: center;
  padding: 45px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
}
.shoppingCart .header .icon {
  position: relative;
}
.shoppingCart:not(.show) .header .icon {
  cursor: pointer;
}
.shoppingCart .header .ic-cart{
  width: 50px;
  height: 50px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiCwwQEgYn7+gWAAABQklEQVRIx9WTsUoDQRRF72xMYSFokcRCjBFESGWRwtZe8AtEFFNrI1bB0tLGQuzs/AJB8AO2kZRCxBiRQNKIoIYoyLFwCZuss9nNgpBXzc7be3j3zow09mU48321zVF8gNtbZ5Q1UwmGYQPIxFU5vnVdUuHfARO+OD5oa9GzM6NcbICkem+CLZ0kA1zKHQ2w6tlpqRUN4AwA5knFiTAISGsuHqDfwoOkgp4kUkoP1WI+Azt02ZYk9hle3cAEBh69c7iKEOJ30IJU/71KpqbaKCH6b0LEGgTcaYXpeIj+GJf54pyI70CSTACxq1M5ehmqvDCHfwIkFrQW4S1WzXUCq+E5lNhkMqSfo0ze3t7hlWeqIfJ3GnQo2n644ZhZYMnSL9OQcKnYAHs0ueUeY+nn6eDyRskGcFjngGxIBkUqVvk41g+oBJ136GBf8AAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0xMS0xMlQxNjoxODowNiswMTowMEVm3zEAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMTEtMTJUMTY6MTg6MDYrMDE6MDA0O2eNAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50%;
}
.shoppingCart .header .countNumb {
  position: absolute;
  width: 18px;
  height: 18px;
  color: #0c0b10;
  font-weight: bold;
  font-size: 0.7em;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  background-color: var(--bs-primary);
  position: absolute;
  bottom: 0px;
  right: 5px;
}
.shoppingCart .header .text {
  font-weight: bold;
  font-size: 1.2em;
}

.shoppingCart:not(.show) .header .icon {
  position: absolute;
  top: 0;
  left: -50px;
  z-index: 2;
  background-color: #0c0b10;
}


.shoppingCart .body {
  overflow: auto;
  height: 100%;
  padding: 0 20px;
}
.shoppingCart .body .empty {
  color: #ececec;
  text-align: center;
  line-height: 40px;
}
.shoppingCart .footer {
  padding: 20px;
  flex-shrink: 0;
  background-color: #1b1a20;
  position: relative;
}
.shoppingCart .footer::before {
  content: "";
  width: 100%;
  height: 20px;
  display: block;
  position: absolute;
  top: -20px;
  left: 0px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent);
}
.shoppingCart .footer .total {
  display: flex;
  justify-content: space-between;
  color: #5b5a5e;
}
.shoppingCart .footer .total b {
  color:var(--bs-primary);
  font-size: 1.25rem;
}
.shoppingCart .footer .checkout {
  width: 100%;
  border: 0px;
  color: rgb(236, 236, 236);
  text-transform: uppercase;
  background-color: rgb(12, 11, 16);
  text-align: center;
  padding: 15px 0px;
  margin-top: 30px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease 0s;
}
.shoppingCart .footer .checkout:hover {
  background-color: var(--bs-primary);
}

.cart-item {
  position: relative;
  padding: 20px 0;
  border-top: 2px solid #0002;
  display: flex;
  gap: 1rem;
}
.cart-item img {
  flex-shrink: 0;
}
.cart-item .info {
  color: #999;
  align-self: center;
  flex-grow: 1;
}
.cart-item .info  .name {
  color: #FFF;
}
.cart-item .right {
  align-self: flex-end;
  flex-shrink: 0;
  text-align: right;
}
.cart-item .right .price {
  color: var(--bs-primary);
  white-space: nowrap;
  margin-bottom: 0.5rem;
}
.cart-item .right .spinbtn button {
  background: #0c0b10;
  color: #eee;
  border: 0;
  width: 24px;
  height: 24px;
  margin-left: 2px;
  cursor: pointer;
}
.cart-item .right .spinbtn button:disabled {
  opacity: .15;
  background-color: #444;
  cursor: default;
}

.loading {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.loading::before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  border-radius: inherit;
  background-image: conic-gradient(from 0 at 50% 50%, rgba(var(--bs-primary-rgb),.1) 0deg, var(--bs-primary) 360deg);
  margin: -3px;
  animation: spin linear 2s infinite;
}
.loading::after {
  content: '';
  position: absolute;
  z-index: 1;
  inset: 0;
  border-radius: inherit;
  background-color: #FFF;
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.loadingWrapper {
  padding: 3rem;
  text-align: center;
}
.loadingWrapper .loading {
  height: 5rem;
  width: 5rem;
}


.modal {
  display: block;
}
.modal-backdrop {
  opacity: 0.5;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
.listOrder .cart-item {
  border-top: 0;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1333333333);
  padding: 15px 0;
  font-size: 0.875rem;
}
.listOrder .cart-item .info .name {
  color: #0c0b10;
  font-weight: 500;
}
.listOrder .cart-item .right .price {
  color: #000;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.btn {
  --bs-border-radius: 0;
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #cea800;
  --bs-btn-hover-border-color: #cea800;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #cea800;
  --bs-btn-active-border-color: #cea800;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-primary);
  --bs-btn-disabled-border-color: var(--bs-primary);
}

.topbar {
  padding: 0.5rem;
  color: #FFF;
  background-color: #1b1a20;
  line-height: 2rem;
  min-height: 50px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
}